import { AccountId, ActionRequestId } from "@ollie-sports/models";
import { getUniversalHelpers } from "../../helpers";
import { validateToken } from "../../internal-utils/server-auth";
import * as express from "express";
import { playerBundle__server__respondToPlayerBundleJoinRequest } from "../playerBundle/playerBundle__respondToPlayerBundleJoinRequest";

export async function actionRequest__server__completeActionRequest(p: {
  actionRequestId: ActionRequestId;
  approve: boolean;
  respondingAccountId: AccountId;
}) {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  const actionRequest = await h.ActionRequest.getDoc(p.actionRequestId);

  if (!actionRequest) {
    throw new Error(`Invalid Action Request Id: ${p.actionRequestId} `);
  }

  const respondingAccount = await h.Account.getDoc(p.respondingAccountId);

  if (!respondingAccount) {
    throw new Error(`Invalid RespondingAccountId: ${p.respondingAccountId}.`);
  }

  await playerBundle__server__respondToPlayerBundleJoinRequest({ actionRequest, respondingAccount, approve: p.approve });

  // SERVER_ONLY_TOGGLE
}

actionRequest__server__completeActionRequest.auth = async (r: express.Request) => {
  await validateToken(r);
  // Make sure valid auth token
  // Make sure user has auth to update this
};

// i18n certified - complete

import { AccountId, ActionRequestId } from "@ollie-sports/models";
import { getUniversalHelpers } from "../../helpers";
import { validateToken } from "../../internal-utils/server-auth";
import * as express from "express";
import { playerBundle__server__respondToPlayerBundleJoinRequest } from "../playerBundle/playerBundle__respondToPlayerBundleJoinRequest";

export async function actionRequest__server__getActionRequest(p: { actionRequestId: ActionRequestId }) {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  return await h.ActionRequest.getDoc(p.actionRequestId);

  // SERVER_ONLY_TOGGLE
}

actionRequest__server__getActionRequest.auth = async (r: express.Request) => {
  await validateToken(r);
  // Make sure valid auth token
  // Make sure user has auth to update this
};

// i18n certified - complete
